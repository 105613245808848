// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_wKW4w {\n  display: flex;\n  flex-direction: column;\n  padding-left: 240px;\n  width: 850px;\n}\n.extendedContainer__ozYa {\n  display: flex;\n  flex-direction: column;\n  padding-left: 24px;\n}\n.radioContainer_jJkqE {\n  display: flex;\n  flex-direction: column;\n  padding-left: 32px;\n}\n.show_payment_schedule_modal_btn__Aizq {\n  width: fit-content;\n  padding: 0 !important;\n  margin: 0;\n  text-align: left;\n}\n", "",{"version":3,"sources":["webpack://./../../packages/@dfa-ui/src/templateComponents/dfaForm/CouponPayments/CouponPayments.m.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAEA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;AAAJ;AAGA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;AADJ;AAIA;EACE,kBAAA;EACA,qBAAA;EACA,SAAA;EACA,gBAAA;AAFF","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    padding-left: 240px;\n    width: 850px;\n}\n\n.extendedContainer {\n    display: flex;\n    flex-direction: column;\n    padding-left: 24px;\n}\n\n.radioContainer {\n    display: flex;\n    flex-direction: column;\n    padding-left: 32px;\n}\n\n.show_payment_schedule_modal_btn {\n  width: fit-content;\n  padding: 0 !important;\n  margin: 0;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_wKW4w",
	"extendedContainer": "extendedContainer__ozYa",
	"radioContainer": "radioContainer_jJkqE",
	"show_payment_schedule_modal_btn": "show_payment_schedule_modal_btn__Aizq"
};
export default ___CSS_LOADER_EXPORT___;
