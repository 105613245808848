import { GetUidsByInnTypeParams, IRequest, ProfileType, ResponseItems } from '@dltru/dfa-models'
import { FC, useContext, useEffect, useState } from 'react'

import { Box } from '../../Box'
import { WrappedRadioItem } from '../../Radio'
import { IStep, IValues, Step, StepperContext, useEditable } from '../../Stepper'
import { Form } from '../../components/Form'
import { EmissionCardPageContext } from '@dltru/dfa-web-client/src/pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'

type ComponentProps = Partial<IStep> & {
    CustomInvestorsBlock?: FC<{
        isEdit: boolean
        noAvailableInn?: string | number | undefined | null
    }>
    stepIndex?: number
    isModification?: boolean
    noAvailableInn?: string | number | undefined | null
    getUids?: (data: GetUidsByInnTypeParams) => Promise<IRequest<ResponseItems<string>>>
}

const radioOptions = [
    { value: false, label: 'все пользователи' },
    { value: true, label: 'ограниченный круг пользователей' },
]
const defaultValue = false

const label = 'В сделках размещения участвуют(ет):'
const fieldName = 'is_limited_circle_investors'

type InvestorsDataType = { client_inn: string; client_type: ProfileType }[]

export const AccessibilityStep: FC<ComponentProps> = ({
    stepIndex = 1,
    CustomInvestorsBlock,
    isModification,
    noAvailableInn,
    getUids,
    ...props
}) => {
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)
    const { setDisabledActionButtons } = useContext(EmissionCardPageContext)
    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        isModification &&
        currentStep >= stepsTotal &&
        (!editable.length || editable.includes(stepIndex))

    const [form] = Form.useForm()
    const initialValue = values?.[fieldName]

    const [isLimitedCircleInvestors, setIsLimitedCircleInvestors] = useState(
        initialValue ?? defaultValue,
    )

    useEffect(() => {
        if (typeof initialValue !== 'boolean') {
            form.setFieldsValue({ [fieldName]: defaultValue })
        } else {
            setIsLimitedCircleInvestors(initialValue)
        }
        setDisabledActionButtons(isEdit)
    }, [initialValue, isEdit])

    const makeSideEffect = async (values: IValues) => {
        const investorsData = values?.investors_data as InvestorsDataType
        if (values?.[fieldName] && investorsData?.length) {
            const dataForRequest = investorsData.reduce((acc, item) => {
                if (item.client_type in acc) {
                    acc[item.client_type]?.push(item.client_inn)
                } else {
                    acc[item.client_type] = [item.client_inn]
                }
                return acc
            }, {} as GetUidsByInnTypeParams)
            const investorUids = await getUids?.(dataForRequest)
            const uniqueUids = investorUids?.data?.items
                ? [...new Set(investorUids.data.items)]
                : []
            values.investors = uniqueUids
            form.setFieldsValue({ investors: uniqueUids })
        }
    }

    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            allowEdit={allowEdit}
            stepTitle="Круг лиц, участвующих в сделках размещения"
            makeSideEffect={getUids ? makeSideEffect : undefined}
            {...props}
        >
            {!isEdit && (
                <Box margin={[0, 0, 14, 240]}>
                    <label htmlFor={fieldName}>{label}</label>
                </Box>
            )}
            <div className="formRow">
                <WrappedRadioItem
                    className={isEdit ? 'formComponentItem' : 'formComponentItem--lg'}
                    name={fieldName}
                    id={fieldName}
                    label={isEdit ? label : undefined}
                    uneditable={isEdit}
                    options={radioOptions}
                    onChange={setIsLimitedCircleInvestors}
                />
            </div>
            {isLimitedCircleInvestors && CustomInvestorsBlock && (
                <CustomInvestorsBlock isEdit={isEdit} noAvailableInn={noAvailableInn} />
            )}
        </Step>
    )
}
