import { FC, useCallback, useState } from 'react'
import { Box } from '../../../Box'
import { WrappedSelect } from '../../../components/Form'
import { calculationMethodOptions, checkBoxLabel, checkBoxLabelDisable, couponPaymentMethodOptions } from './consts'
import { Button } from 'antd'
import styles from './CouponPayments.m.less'
import { PaymentsScheduleModal } from './PaymentsScheduler/PaymentsScheduleModal'
import { IDfaFront, TCouponPaymentDate } from '@dltru/dfa-models'
import { rublesLabel } from '../../../utils'

interface ICouponePaymentsViewProps {
    dates: TCouponPaymentDate[]
    values?: IDfaFront
}

export const CouponPaymentsView: FC<ICouponePaymentsViewProps> = ({ 
  dates,
  values,
 }) => {
    const [isPaymentsScheduleModalVisible, setPaymentsScheduleModalVisible] = useState(false)
    const couponPaymentAmount = values?.coupon_payment_amount && `${values.coupon_payment_amount} ${rublesLabel(values.coupon_payment_amount) }`

    const openPaymentsScheduleModal = useCallback(() => {
      setPaymentsScheduleModalVisible(true)
    }, [])

    const closePaymentsScheduleModal = useCallback(() => {
      setPaymentsScheduleModalVisible(false)
    }, [])

    return (
        <>
            <Box margin={[0, 0, 14, 240]}>
              <label>{values?.is_coupon_payment ? checkBoxLabel : checkBoxLabelDisable}</label>
            </Box>
              {values?.is_coupon_payment && (
                <>
                    {values?.coupon_payment_method_type && 
                      <>
                        <div className="formRow">
                          <WrappedSelect
                            label="Способ выплаты"
                            name="coupon_payment_method_type"
                            uneditable
                            disabled
                            options={couponPaymentMethodOptions}
                            className="formComponentItem"
                          />
                        </div>
                        <div className="formRow">
                          <div className='ant-row ant-form-item form-item formComponentItem form-item--uneditable'>
                            <div className='ant-col ant-form-item-label'></div>
                            <div className='ant-col ant-form-item-control'>
                              <p>
                                {[couponPaymentAmount, values?.coupon_payment_amount_description].filter(Boolean).join(' + ')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                    
                    <div className="formRow">
                        <WrappedSelect
                            label="Способ расчетов"
                            name="coupon_payment_collect_type"
                            uneditable
                            disabled
                            options={calculationMethodOptions}
                            className="formComponentItem"
                        />
                    </div>
                    <div className="formRow">
                      <div className='ant-row ant-form-item form-item formComponentItem form-item--uneditable'>
                        <div className='ant-col ant-form-item-label'>
                          <label>График выплат</label>
                        </div>
                        <div className='ant-col ant-form-item-control'>
                          <Button 
                            type='link' 
                            className={styles.show_payment_schedule_modal_btn}
                            onClick={openPaymentsScheduleModal}
                            >
                              Посмотреть график выплат
                            </Button>
                        </div>
                      </div>
                    </div>
                </>
            )}
            <PaymentsScheduleModal
              visible={isPaymentsScheduleModalVisible}
              onCancel={closePaymentsScheduleModal}
              dates={dates}
              isPaidBeforeHoliday={values?.is_coupon_paid_before_holiday_or_weekend}
            />

        </>
    )
}
