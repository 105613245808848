import { ECouponPaymentCollectType, ECouponPaymentMethod, IDfaFront, TAddDates, TCouponPaymentDate } from '@dltru/dfa-models'
import { FC, useCallback, useContext, useEffect } from 'react'
import { Form } from '../../../components/Form'
import { Step, StepperContext, useEditable } from '../../../Stepper'
import { CouponPaymentsEdit } from './CouponPaymentsEdit'
import { CouponPaymentsView } from './CouponPaymentsView'

import { CouponPaymentForm } from './types'
import { openMessage } from '../../../Notification'

const STEP_INDEX = 0

interface IComponentProps {
    stepIndex: number
    allowEdit?: boolean
    saveAfterEdit?: boolean
    backFromFirstStep?: () => void
    dates: TCouponPaymentDate[]
    addDates: (data: TAddDates) => void
    saveAfterEditDates: (dates: TCouponPaymentDate[]) => void
}

export const CouponPayments: FC<IComponentProps> = ({
    backFromFirstStep,
    stepIndex = STEP_INDEX,
    allowEdit,
    saveAfterEdit,
    dates,
    addDates,
    saveAfterEditDates,
}) => {
    const { editable, currentStep, stepsTotal, values, mergeValuesIntoState } = useContext(StepperContext)
    const [form] = Form.useForm<CouponPaymentForm>()

    const isEdit = useEditable(currentStep, stepIndex, editable)

    let isAllowEdit =
        currentStep >= stepsTotal && (!editable.length || editable.includes(stepIndex))
    if (allowEdit !== undefined) {
        isAllowEdit = allowEdit
    }

    const couponPaymentEnableChangeHandler = useCallback((is_coupon_payment: boolean) => {
        let values: Partial<CouponPaymentForm> = {}
        if (!is_coupon_payment) {
            values = {
                is_coupon_payment,
                is_coupon_paid_before_holiday_or_weekend: undefined,
                coupon_payment_collect_type: undefined,
                coupon_payment_amount_description: undefined,
                coupon_payment_method_type: ECouponPaymentMethod.FIXED,
                coupon_payment_amount: undefined
            }
        } else {
            values = {
                is_coupon_payment,
                is_coupon_paid_before_holiday_or_weekend: false,
                coupon_payment_collect_type: ECouponPaymentCollectType.account,
                coupon_payment_amount_description: undefined,
                coupon_payment_method_type: ECouponPaymentMethod.FIXED,
                coupon_payment_amount: undefined
            }
        }
        mergeValuesIntoState(values)
    }, [])

    const couponPaymentMethodChangeHandler = useCallback((coupon_payment_method_type: ECouponPaymentMethod) => {
      const values = {
        coupon_payment_method_type,
        coupon_payment_amount_description: undefined,
        coupon_payment_amount: undefined,
      }
      mergeValuesIntoState(values)
    }, [])

    useEffect(() => {
        if (values) {
            const typedValues = values as unknown as IDfaFront
            form.setFieldsValue({
                is_coupon_paid_before_holiday_or_weekend:
                    typedValues.is_coupon_paid_before_holiday_or_weekend,
                coupon_payment_collect_type: typedValues.coupon_payment_collect_type,
                coupon_payment_amount_description: typedValues.coupon_payment_amount_description,
                is_coupon_payment: typedValues.is_coupon_payment,
                coupon_payment_method_type: typedValues.coupon_payment_method_type,
                coupon_payment_amount: typedValues.coupon_payment_amount,
            })
        }
    }, [values])

    const preValidation = useCallback(() => {
      if (!dates.length && form.getFieldValue('is_coupon_payment')) {
        openMessage({type: 'error', message: 'Добавьте как минимум одну дату выплат в график'})
        return false
      }
      return true
    }, [dates])

    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            stepTitle="Выплаты купонного дохода"
            allowEdit={isAllowEdit}
            saveAfterEdit={saveAfterEdit}
            backFromFirstStep={backFromFirstStep}
            preValidation={preValidation}
        >
            {isEdit ? (
                <CouponPaymentsView
                    dates={dates}
                    values={(values as unknown as IDfaFront)}
                />
            ) : (
                <CouponPaymentsEdit
                    dates={dates}
                    addDates={addDates}
                    saveAfterEditDates={saveAfterEditDates}
                    onCouponPaymentChange={couponPaymentEnableChangeHandler}
                    onCouponPaymentMethodChange={couponPaymentMethodChangeHandler}
                    values={(values as unknown as IDfaFront)}
                />
            )}
        </Step>
    )
}
