import { FC, useCallback, useState } from 'react'
import { Alert } from '../../../Alert'
import { Checkbox, CheckboxChangeEvent } from '../../../Checkbox'
import { Form, WrappedInput, WrappedSelect } from '../../../components/Form'
import { Divider } from '../../../Divider'
import { WrappedRadioItem } from '../../../Radio'
import { alertDescription, calculationMethodOptions, checkBoxLabel, couponPaymentMethodOptions, radioOptions } from './consts'
import styles from './CouponPayments.m.less'
import { PaymentsScheduler } from './PaymentsScheduler/PaymentsScheduler'
import { ECouponPaymentCollectType, TAddDates, TCouponPaymentDate, ECouponPaymentMethod, IDfaFront } from '@dltru/dfa-models'
import { currentFloatNumber, dfaValidators } from '../../../utils'

interface ICouponPaymentsEditProps {
    values?: IDfaFront
    onCouponPaymentChange: (value: boolean) => void
    dates: TCouponPaymentDate[]
    addDates: (data: TAddDates) => void
    saveAfterEditDates: (dates: TCouponPaymentDate[]) => void
    onCouponPaymentMethodChange: (value: ECouponPaymentMethod) => void
}

const mapCouponPaymentAmountInputsToMethod: Record<ECouponPaymentMethod, JSX.Element> = {
  [ECouponPaymentMethod.FIXED] : <>
      <WrappedInput
        column
        label="Размер выплаты"
        name="coupon_payment_amount"
        uneditable={false}
        required
        placeholder="Укажите размер выплаты за единицу ЦФА"
        normalize={currentFloatNumber}
        rules={[{ validator: dfaValidators.couponPaymentAmount }]}
      />
    </>,
    [ECouponPaymentMethod.DETERMINED]: <>
      <WrappedInput
        column
        label="Размер выплаты"
        name="coupon_payment_amount_description"
        multiline
        uneditable={false}
        required
        placeholder="Опишите порядок определения размера выплаты за единицу ЦФА"
      />
    </>,
    [ECouponPaymentMethod.COMBINED]: <>
      <WrappedInput
        column
        label="Размер выплаты"
        name="coupon_payment_amount"
        uneditable={false}
        required
        placeholder="Укажите размер выплаты за единицу ЦФА"
        normalize={currentFloatNumber}
        rules={[{ validator: dfaValidators.couponPaymentAmount }]}
      />
      <WrappedInput
        column
        label="Дополнение"
        name="coupon_payment_amount_description"
        multiline
        uneditable={false}
        required
        placeholder="Опишите порядок определения размера выплаты за единицу ЦФА"
      />
    </>

}

export const CouponPaymentsEdit: FC<ICouponPaymentsEditProps> = ({
    onCouponPaymentChange,
    dates,
    addDates,
    saveAfterEditDates,
    onCouponPaymentMethodChange,
    values
}) => {
    const [isShow, setIsShow] = useState(values?.is_coupon_payment)
    const [couponPaymentMethod, setCouponPaymentMethod] = useState(values?.coupon_payment_method_type || ECouponPaymentMethod.FIXED)

    const onCouponePaymentEnableHandler = useCallback((e: CheckboxChangeEvent) => {
        setIsShow(e.target.checked)
        onCouponPaymentChange(e.target.checked)
    }, [])

    const onChangeCouponPaymentMethod = useCallback((method: ECouponPaymentMethod) => {
      setCouponPaymentMethod(method)
      onCouponPaymentMethodChange(method)
    }, [])

    return (
        <div className={styles.container}>
            <Form.Item name="is_coupon_payment" valuePropName="checked">
                <Checkbox name="couponePaymentEnable" onChange={onCouponePaymentEnableHandler}>
                    {checkBoxLabel}
                </Checkbox>
            </Form.Item>
            {isShow && (
                <div className={styles.extendedContainer}>
                    <WrappedSelect
                      label="Способ выплаты"
                      column
                      name="coupon_payment_method_type"
                      options={couponPaymentMethodOptions}
                      uneditable={false}
                      onChange={onChangeCouponPaymentMethod}
                    />

                    {mapCouponPaymentAmountInputsToMethod[couponPaymentMethod]}

                    <Alert showIcon type="info" description={alertDescription} />

                    <WrappedSelect
                        label="Способ расчетов"
                        column
                        name="coupon_payment_collect_type"
                        options={calculationMethodOptions}
                        uneditable={false}
                        initialValue={ECouponPaymentCollectType.account}
                        disabled
                    />
                    <Divider />

                    <PaymentsScheduler
                        dates={dates}
                        addDates={addDates}
                        saveAfterEditDates={saveAfterEditDates}
                    />

                    <div className={styles.radioContainer}>
                        <p>
                            Если дата выплаты выпадет на выходной или праздничный день, то выплата
                            купонного дохода будет произведена в ближайший рабочий день:
                        </p>

                        <WrappedRadioItem
                            id="is_coupon_paid_before_holiday_or_weekend"
                            name="is_coupon_paid_before_holiday_or_weekend"
                            uneditable={false}
                            options={radioOptions}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
